<template>
  <b-container
    fluid
    class="mb-20 mb-md-40"
    :class="currentLocale == 'en' ? 'eng' : 'arabic'"
  >
    <transition-group
      tag="div"
      class="slider-steps"
      :name="back ? 'slideback' : 'slide'"
    >
      <section class="header-section steps" v-if="currentStep === 1" key="1">
        <b-row class="m-0" :class="currentLocale == 'en' ? 'rtl' : 'ltr'">
          <b-col class="col-12 col-md-5 p-0" v-if="currentLocale == 'ar'">
            <b-img src="media/svg/moon-assets/join-banner.png" />
          </b-col>
          <b-col
            class="col-12 col-md-5 p-0 rtl"
            :class="currentLocale == 'en' ? 'ml-auto' : ''"
            v-if="currentLocale == 'en'"
          >
            <b-img src="media/svg/moon-assets/join-banner-eng.png" />
          </b-col>
          <b-col
            align-self="center"
            class="col-12 col-md-5"
            :class="currentLocale == 'en' ? 'ltr pl-md-40' : 'rtl'"
          >
            <h1>{{ $t('join.contact.h1') }}</h1>
            <br />
            <br />
            <b-form>
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text"
                    ><i class="fa fa-user fa-lg"></i
                  ></span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  v-model="name"
                  size="lg"
                  :placeholder="$t('join.contact.namePlaceHolder')"
                >
                </b-form-input>
              </b-input-group>

              <b-input-group>
                <b-input-group-prepend
                  :class="phone && !isPhoneValidate ? 'has-error' : ''"
                >
                  <span class="input-group-text"
                    ><i class="fa fa-phone fa-lg"></i
                  ></span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  type="text"
                  v-model="phone"
                  @keyup="validatePhone"
                  size="lg"
                  :placeholder="$t('join.contact.phonePlaceholder')"
                >
                </b-form-input>
              </b-input-group>

              <h2>{{ $t('join.contact.h2') }}</h2>
              <b-row class="mb-4 mt-2">
                <b-col cols="auto">
                  <b-avatar
                    button
                    @click="isInstagram = !isInstagram"
                    icon="instagram"
                    :class="isInstagram ? 'active-social' : ''"
                  ></b-avatar>
                </b-col>
                <b-col cols="auto">
                  <b-avatar
                    button
                    @click="isTwitter = !isTwitter"
                    icon="twitter"
                    :class="isTwitter ? 'active-social' : ''"
                  ></b-avatar>
                </b-col>
                <b-col cols="auto">
                  <b-avatar
                    button
                    @click="isSnapchat = !isSnapchat"
                    :class="isSnapchat ? 'active-social' : ''"
                    ><i class="fab fa-snapchat"></i>
                  </b-avatar>
                </b-col>
              </b-row>
              <h6 class="error-text" v-if="socialLinkValidation">
                {{ $t('join.contact.warning') }}
              </h6>
              <b-button
                class="submit-btn"
                @click="moveForward"
                :disabled="!isformValidated"
                >{{ $t('join.contact.continueBtn') }}</b-button
              >
            </b-form>
          </b-col>
        </b-row>
      </section>

      <!-- // STEP 2 for prospect details -->
      <SocialLinkForm
        class="header-section steps"
        :class="currentLocale == 'en' ? 'rtl text-left' : 'ltr text-right'"
        v-if="currentStep === 2"
        key="2"
        currentStep="snapchat"
        :isInstagram="isInstagram"
        :isSnapchat="isSnapchat"
        :isTwitter="isTwitter"
        insightImage="media/svg/moon-assets/sc-insight.png"
        @detail-submit="snaphatInfo"
      />

      <!-- // STEP 3 for prospect details -->
      <SocialLinkForm
        class="header-section steps"
        :class="currentLocale == 'en' ? 'rtl text-left' : 'ltr text-right'"
        v-if="currentStep === 3"
        key="3"
        currentStep="instagram"
        :isInstagram="isInstagram"
        :isSnapchat="isSnapchat"
        :isTwitter="isTwitter"
        insightImage="media/svg/moon-assets/new-insta-ss1.png"
        @detail-submit="instagramInfo"
      />

      <!-- // STEP 4 for prospect details -->
      <SocialLinkForm
        class="header-section steps"
        :class="currentLocale == 'en' ? 'rtl text-left' : 'ltr text-right'"
        v-if="currentStep === 4"
        key="4"
        currentStep="twitter"
        :isInstagram="isInstagram"
        :isSnapchat="isSnapchat"
        :isTwitter="isTwitter"
        insightImage="media/svg/moon-assets/twitter-insights.png"
        @detail-submit="twitterInfo"
      />

      <ReferalForm
        class="header-section steps"
        v-if="currentStep === 5"
        @referal-list="getReferalList"
        key="5"
      />

      <section class="header-section steps" v-if="currentStep === 6" key="6">
        <b-row class="text-center mt-40 p-2">
          <b-col align-self="center">
            <b-icon
              class="success-icon col-12 col-md-6"
              icon="check-circle-fill"
            />
            <br /><br />
            <h1>{{ $t('join.thankyou.h1') }}</h1>
            <br /><br />
            <h2>
              {{ $t('join.thankyou.h2') }}
            </h2>
          </b-col>
        </b-row>
      </section>
    </transition-group>
    <!-- Header -->
  </b-container>
</template>

<script>
import SocialLinkForm from '../../content/widgets/moon/social-link-form.vue';
import ReferalForm from '../../content/widgets/moon/moon-referal.vue';
import { SET_LOCALE } from '@/core/services/store/auth.module';

import axios from 'axios';

export default {
  name: 'MoonJoinPage',
  components: {
    SocialLinkForm,
    ReferalForm,
  },
  data() {
    return {
      isTwitter: false,
      isSnapchat: false,
      isInstagram: false,
      name: '',
      phone: '',
      currentStep: 1,
      back: false,
      payload: new FormData(),
      references: [],
      country: '',
      isPhoneValidate: false,
    };
  },
  created() {
    const referalParam = this.$route.query.referal;
    if (referalParam) {
      // if comes from referal link redirect to referal step.
      this.currentStep = 5;
    }

    const countryParam = this.$route.query.country;
    if (countryParam) {
      // if there is any country params.
      this.country = countryParam;
    }

    const langParam = this.$route.query.lang;
    if (langParam && (langParam == 'ar' || langParam == 'en')) {
      // if comes language param then set currentLocale according to them.
      this.changeLocale(langParam);
    }
  },
  methods: {
    /* method to move to the next step */
    moveForward() {
      if (this.currentStep === 6) {
        this.$router.push({ name: 'landing-page' });
        return;
      }

      // datalayer to capture first step
      if (this.currentStep === 1) {
        dataLayer.push({ event: 'contact-info' });
      }
      this.currentStep = this.currentStep + 1;
    },
    /* getting snapchat info here if user select. */
    snaphatInfo(data) {
      if (data) {
        this.payload.append(
          'social_media[snapchat][followers]',
          data.no_of_followers
        );
        this.payload.append(
          'social_media[snapchat][account_handle]',
          data.link
        );
        this.payload.append('social_media[snapchat][images][]', data.image);
        this.payload.append('social_media[snapchat][platform]', 'Snapchat');
      }

      this.moveForward();
      // datalayer to capture account info event if user submit snapchat form
      if (data) {
        dataLayer.push({ event: 'account-info' });
      }
    },

    /* getting instagram info here if user select. */
    instagramInfo(data) {
      if (data) {
        this.payload.append(
          'social_media[instagram][followers]',
          data.no_of_followers
        );
        this.payload.append(
          'social_media[instagram][instagram_handle]',
          data.link
        );
        this.payload.append('social_media[instagram][images][]', data.image);
        this.payload.append('social_media[instagram][platform]', 'Instagram');
      }

      this.moveForward();
      // datalayer to capture account info event if user submit instagram form
      if (data) {
        dataLayer.push({ event: 'account-info' });
      }
    },

    /* getting twitter handle info here if user select. */
    twitterInfo(data) {
      if (data) {
        this.payload.append(
          'social_media[twitter][followers]',
          data.no_of_followers
        );
        this.payload.append('social_media[twitter][twitter_handle]', data.link);
        this.payload.append('social_media[twitter][images][]', data.image);
        this.payload.append('social_media[twitter][platform]', 'Twitter');
      }

      this.moveForward();
      // datalayer to capture account info event if suer submit twitter form
      if (data) {
        dataLayer.push({ event: 'account-info' });
      }
    },

    /* getting all referal list here from child component. */
    getReferalList(list) {
      this.references = JSON.parse(JSON.stringify(list));
      // hit api here to save response
      this.Onsubmit();
      // datalayer to capture referral info event
      dataLayer.push({ event: 'referral info' });
    },

    /* Send response to server */
    Onsubmit() {
      this.payload.append('name', this.name);
      this.payload.append('phone_number', this.phone);
      this.payload.append('country', this.country);

      // mapping references according to API format.
      this.references.forEach((element, index) => {
        this.payload.append(`references[${index}][email]`, element.email);
        this.payload.append(
          `references[${index}][mobile_number]`,
          element.mobile_number
        );
        this.payload.append(`references[${index}][name]`, element.name);
      });

      const instance = axios.create({
        baseURL: 'https://api.ims.directory/api/v1/',
      });

      instance.post('/moon', this.payload).then((res) => {
        console.log('Response sent.', res);
        this.moveForward();
        // datalayer to capture successful submission
        dataLayer.push({ event: 'Successful submission' });
      });
    },
    validatePhone(phoneNumber) {
      let regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
      let regexArabic = new RegExp(
        /^[+]*[(]{0,1}[\u0660-\u0669\u06F0-\u06F9]{1,4}[)]{0,1}[-\s\./\u0660-\u0669\u06F0-\u06F9]*$/g
      );
      // let englishAndArabicNumber = new RegExp(
      //   /^[+]*[(]{0,1}[\u0660-\u0669\u06F0-\u06F90-9]{1,4}[)]{0,1}[-\s\./\u0660-\u0669\u06F0-\u06F90-9]*$/g
      // );
      this.isPhoneValidate =
        regex.test(phoneNumber.target.value) ||
        regexArabic.test(phoneNumber.target.value);
    },
    changeLocale(e) {
      this.$i18n.locale = e;
      localStorage.setItem('locale', e);
      this.$store.dispatch(SET_LOCALE, e);
    },
  },

  computed: {
    isformValidated() {
      return (
        this.phone &&
        this.isPhoneValidate &&
        this.name &&
        (this.isTwitter || this.isInstagram || this.isSnapchat)
      );
    },
    socialLinkValidation() {
      return (
        this.name &&
        this.phone &&
        !(this.isTwitter || this.isInstagram || this.isSnapchat)
      );
    },
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 0.7s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.7s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}

.slider-steps {
  overflow: hidden;
  position: relative;
  min-height: 90vh;
  width: 100%;
  .steps {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
.submit-btn {
  background: #324772 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 140px;
  height: 45px;
}

.header-section {
  h1 {
    font-size: 50px;
    line-height: 55px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #212121;
  }
  h2 {
    font-size: 18px;
    line-height: 45px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #212121;
  }
  .error-text {
    font-size: 13px;
    line-height: 30px;
    font-weight: normal;
    color: #dc0a0af2;
    font-weight: bold;
  }

  img {
    width: 80%;
  }
  .fa-snapchat {
    padding: 10px;
    font-size: 30px;
    color: #a5a8b5 !important;
  }
  form {
    input {
      height: 50px;
      background: #efefef 0% 0% no-repeat padding-box;
      border-radius: 0px;
      opacity: 0.49;
      font-size: 14px;
    }
    input:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    .input-group {
      padding: 0 0 15px 0;
    }

    .input-group-text {
      width: 48px;
      height: 50px;
      border-left: none;
      background: #efefef 0% 0% no-repeat padding-box;
      color: #cfa918;
      border-radius: 0;
      opacity: 0.49;
    }
    .fa-user,
    .fa-phone {
      display: inline-block;
      width: 100%;
      color: #cfa918;
    }
    .LoginInput {
      border-right: none;
    }

    .active-social {
      background: #324772 0% 0% no-repeat padding-box !important;
      color: #ffffff !important;
      i {
        color: #3f4254 !important;
        background: #fff;
      }
    }
  }
}
.has-error {
  background: red;
}

.success-icon {
  font-size: 60px;
  color: #5dbe9b;
}
// Tablet & Mobile Modes
@media only screen and (max-width: 600px) {
  .slider-steps {
    min-height: 700px;
  }
}
</style>
