<template>
  <section>
    <b-row class="m-0" :class="currentLocale == 'en' ? 'rtl' : 'ltr'">
      <b-col class="col-12 col-md-5 p-0" v-if="currentLocale == 'ar'">
        <b-img src="media/svg/moon-assets/referal-baner.png" />
      </b-col>
      <b-col
        class="col-12 col-md-5 p-0 rtl"
        :class="currentLocale == 'en' ? 'ml-auto' : ''"
        v-if="currentLocale == 'en'"
      >
        <b-img src="media/svg/moon-assets/referal-baner-eng.png" />
      </b-col>
      <b-col
        align-self="center"
        class="col-12 col-md-6"
        :class="currentLocale == 'en' ? 'ltr pl-md-40' : 'rtl'"
      >
        <h1>{{ $t('join.referal.h1') }}</h1>
        <br />
        <b-form v-for="(item, index) in referalList" :key="index">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text"
                ><i class="fa fa-user fa-lg"></i
              ></span>
            </b-input-group-prepend>
            <b-form-input
              class="input-field"
              id="name"
              v-model="item.name"
              type="text"
              size="lg"
              :placeholder="$t('join.referal.name')"
            >
            </b-form-input>
          </b-input-group>

          <b-input-group>
            <b-input-group-prepend
              :class="
                item.mobile_number && index == currentIndex && !isPhoneValidate
                  ? 'has-error'
                  : ''
              "
            >
              <span class="input-group-text"
                ><i class="fa fa-phone fa-lg"></i
              ></span>
            </b-input-group-prepend>
            <b-form-input
              class="input-field"
              id="phone"
              v-model="item.mobile_number"
              @keyup="validatePhone"
              :data-order="index"
              type="text"
              size="lg"
              aria-invalid="false"
              :placeholder="$t('join.referal.phone')"
            >
            </b-form-input>
          </b-input-group>

          <b-input-group>
            <b-input-group-prepend
              :class="
                item.email && index == currentIndex && !emailCheck
                  ? 'has-error'
                  : ''
              "
            >
              <span class="input-group-text"
                ><i class="fa fa-envelope fa-lg"></i
              ></span>
            </b-input-group-prepend>
            <b-form-input
              class="input-field"
              v-model="item.email"
              @keyup="isEmailValid"
              id="email"
              :data-order="index"
              size="lg"
              type="email"
              required
              :placeholder="$t('join.referal.email')"
            >
            </b-form-input>
          </b-input-group>
        </b-form>
        <div
          class="mb-5 ml-3 mr-3"
          :class="currentLocale == 'en' ? 'rtl text-left' : 'ltr text-right'"
        >
          <span class="icon-info">{{ $t('join.referal.addAnother') }}</span>
          <span class="plus-icon" role="button">
            <b-icon @click="addToList" icon="plus-circle-fill" />
          </span>
        </div>
        <div
          :class="currentLocale == 'en' ? 'rtl text-left' : 'ltr text-right'"
        >
          <button class="skip-btn" @click="submit">
            {{ $t('join.referal.skipBtn') }}
          </button>
          <b-button
            class="submit-btn"
            @click="submit"
            :disabled="!emailCheck"
            >{{ $t('join.referal.submitBtn') }}</b-button
          >
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'MoonReferal',

  components: {},

  data() {
    return {
      referalList: [
        {
          name: '',
          phone: '',
          email: '',
        },
      ],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      emailCheck: false,
      currentIndex: 0,
      isPhoneValidate: false,
    };
  },
  mounted() {},

  computed: {
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },

  methods: {
    submit() {
      this.$emit('referal-list', this.referalList);
    },
    addToList() {
      if (!this.emailCheck) return;
      this.referalList.push(
        JSON.parse(
          JSON.stringify({
            name: '',
            mobile_number: '',
            email: '',
          })
        )
      );
    },
    isEmailValid(e) {
      this.currentIndex = e.target.getAttribute('data-order');
      return e.target.value == ''
        ? ''
        : this.reg.test(e.target.value)
        ? (this.emailCheck = true)
        : (this.emailCheck = false);
    },
    validatePhone(phoneNumber) {
      this.currentIndex = phoneNumber.target.getAttribute('data-order');
      let regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
      let regexArabic = new RegExp(
        /^[+]*[(]{0,1}[\u0660-\u0669\u06F0-\u06F9]{1,4}[)]{0,1}[-\s\./\u0660-\u0669\u06F0-\u06F9]*$/g
      );
      this.isPhoneValidate =
        regex.test(phoneNumber.target.value) ||
        regexArabic.test(phoneNumber.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.skip-btn {
  background: #324772 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 140px;
  height: 45px;
  margin-right: 10px;
  margin-left: 10px;
}
.submit-btn {
  background: #324772 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 140px;
  height: 45px;
}
h1 {
  font-size: 36px;
  line-height: 55px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}
h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #212121;
}

img {
  width: 100%;
}
form {
  border-radius: 17px;
  opacity: 1;
  padding: 15px;
  margin: 0px 0px 20px 0px;
  input {
    height: 50px;
    background: #efefef 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 0.49;
    font-size: 14px;
  }
  input:focus {
    background: #efefef 0% 0% no-repeat padding-box;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .input-group {
    padding: 0 0 15px 0;
  }

  .input-group-text {
    width: 48px;
    height: 50px;
    border-left: none;
    background: #efefef 0% 0% no-repeat padding-box;
    color: #cfa918;
    border-radius: 0;
    opacity: 0.49;
  }
  .fa-user,
  .fa-phone,
  .fa-envelope {
    display: inline-block;
    width: 100%;
    color: #cfa918;
  }
  .input-field {
    border-right: none;
  }

  .active-social {
    background: #324772 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    i {
      color: #fff !important;
    }
  }
}
.plus-icon {
  font-size: 35px;
  // padding: 10px;
  line-height: 30px;
  color: #5dbe9b;
  // float: right;
}
.icon-info {
  font-size: 18px;
  line-height: 38px;
  margin: 12px;
  font-weight: bold;
}
.has-error {
  background: red;
}

// Tablet & Mobile Modes
@media only screen and (max-width: 600px) {
  .submit-btn,
  .skip-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
