import { render, staticRenderFns } from "./MoonJoinPage.vue?vue&type=template&id=6eabdaa0&scoped=true"
import script from "./MoonJoinPage.vue?vue&type=script&lang=js"
export * from "./MoonJoinPage.vue?vue&type=script&lang=js"
import style0 from "./MoonJoinPage.vue?vue&type=style&index=0&id=6eabdaa0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eabdaa0",
  null
  
)

export default component.exports