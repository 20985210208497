<template>
  <section>
    <b-row>
      <!-- FORM TO COLLECT INFO OF SOCIAL LINKS -->
      <b-col class="col-12 col-md-4 p-10">
        <b-form :class="currentLocale == 'en' ? 'ltr' : 'rtl'">
          <b-form-group
            id="account-link"
            :label="$t('join.socialMedia.link')"
            label-for="account-link"
          >
            <b-form-input
              id="account-link"
              :placeholder="$t('join.socialMedia.linkPlaceholder')"
              type="text"
              v-model="account_link"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="followers"
            :label="$t('join.socialMedia.follower')"
            label-for="followers"
          >
            <b-form-input
              id="followers"
              :placeholder="$t('join.socialMedia.followerPlaceholder')"
              type="number"
              v-model="no_of_followers"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="insights"
            label-for="insights"
            :label="$t('join.socialMedia.insight')"
          >
            <div class="fileUploadDiv">
              <input type="file" accept="image/*" @change="onChangeInput" />
              <span class="file-name">
                {{
                  logoUrl ? logoUrl : $t("join.socialMedia.insightPlaceholder")
                }}
              </span>
              <span class="plus-icon">
                <b-icon icon="plus-circle-fill" />
              </span>
            </div>
          </b-form-group>
          <label class="d-block d-md-none"
            >{{ $t("join.socialMedia.infoCircleText") }}
            <b-icon v-b-modal.modal-tall scrollable icon="question-circle-fill"
          /></label>
          <b-button
            @click="submit"
            :disabled="!isFormValidate"
            class="submit-btn"
            >{{ $t("join.socialMedia.submitBtn") }}</b-button
          >
        </b-form>
      </b-col>

      <!-- INFO -->
      <b-col align-self="center" class="col-12 col-md-5 p-10 d-none d-md-block">
        <h1 class="main-heading">{{ $t("join.socialMedia.infoHeading") }}</h1>

        <h2 class="sub-heading">
          <span class="float-left mr-5" v-if="currentLocale == 'en'">01</span>
          {{ $t("join.socialMedia.infoSubHeading1") }}
          <span class="float-right ml-5" v-if="currentLocale == 'ar'">01</span>
        </h2>
        <v-divider />

        <h2 class="sub-heading">
          <span class="float-left mr-5" v-if="currentLocale == 'en'">02</span>
          {{ $t("join.socialMedia.infoSubHeading2") }}
          <span class="float-right ml-5" v-if="currentLocale == 'ar'">02</span>
        </h2>
        <v-divider />
      </b-col>

      <!-- INSIGHTS -->
      <b-col
        class="col-12 col-md-3 d-none d-md-block"
        :class="currentLocale == 'en' ? 'pl-10' : 'pr-10'"
      >
        <b-row class="mb-5 mt-2">
          <div
            class="social ltr"
            :class="currentLocale == 'ar' ? 'text-right' : ''"
          >
            <b-avatar
              v-if="isSnapchat"
              button
              :class="currentStep === 'snapchat' ? 'active-social' : ''"
              ><i class="fab fa-snapchat"></i>
            </b-avatar>

            <b-avatar
              v-if="isInstagram"
              button
              icon="instagram"
              :class="currentStep === 'instagram' ? 'active-social' : ''"
            ></b-avatar>

            <b-avatar
              v-if="isTwitter"
              button
              icon="twitter"
              :class="currentStep === 'twitter' ? 'active-social' : ''"
            ></b-avatar>
          </div>
          <b-col cols="12">
            <h1><strong>Hi @khalid786</strong></h1>
            <strong>{{ handle }}</strong>
          </b-col>
        </b-row>
        <div class="insights">
          <b-img :src="insightImage" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-tall"
      centered
      title="BootstrapVue"
      hide-footer
      hide-header
    >
      <b-row class="mb-20">
        <b-col>
          <b-img src="media/svg/moon-assets/header_logo.png" width="80px" />
        </b-col>
        <b-col class="text-right" style="font-size: 45px">
          <b-icon icon="x" @click="$bvModal.hide('modal-tall')" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h1 class="mobile-heading">
            {{ $t("join.socialMedia.mobileHeading") }}
          </h1>
          <br /><br />
          <b-img class="insight-image" :src="insightImage" />
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "SocialLinkForm",

  components: {},
  props: {
    currentStep: "",
    isTwitter: {
      type: Boolean,
      default: true,
    },
    isInstagram: {
      type: Boolean,
      default: true,
    },
    isSnapchat: {
      type: Boolean,
      default: true,
    },
    insightImage: {
      type: String,
      default: "media/svg/moon-assets/insights.png",
    },
  },
  data() {
    return {
      account_link: "",
      no_of_followers: null,
      file: null,
      logoUrl: "",
    };
  },
  mounted() {
    if (this.currentStep === "twitter" && !this.isTwitter) {
      this.$emit("detail-submit");
    }

    if (this.currentStep === "snapchat" && !this.isSnapchat) {
      this.$emit("detail-submit");
    }

    if (this.currentStep === "instagram" && !this.isInstagram) {
      this.$emit("detail-submit");
    }
  },

  computed: {
    handle() {
      switch (this.currentStep) {
        case "snapchat":
          return "SNAPCHAT ACCOUNT";
        case "twitter":
          return "TWITTER ACCOUNT";
        case "instagram":
          return "INSTAGRAM BUSINESS ACCOUNT";
        default:
          "";
          break;
      }
    },
    isFormValidate() {
      if (this.account_link && this.no_of_followers && this.file) {
        return true;
      }
      return false;
    },
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },
  methods: {
    onChangeInput(e) {
      this.file = e.target.files || e.dataTransfer.files;
      if (!this.file.length) return;

      this.logoUrl = this.file[0].name;
      this.file = e.target.files[0];
    },
    submit() {
      this.$emit("detail-submit", {
        link: this.account_link,
        no_of_followers: this.no_of_followers,
        image: this.file,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  background: #324772 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 140px;
  height: 45px;
  margin-top: 6px;
}
.main-heading {
  // text-align: right;
  font-size: 36px;
  line-height: 39px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
  line-height: 100px;
}
.sub-heading {
  // text-align: right;
  font-size: 21px;
  line-height: 29px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #1c1c1c;
  line-height: 40px;
  font-weight: 600;
}
.insights {
  background: #f7f8f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 28px #00000012;
  border-radius: 17px;
  text-align: center;
  padding: 10px;
  height: 550px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}
.social {
  float: right;
  // text-align: right;
  width: 95%;
  padding: 10px;
  button {
    margin: 0 10px 0 10px;
  }
}
.fa-snapchat {
  padding: 10px;
  font-size: 30px;
  color: #a5a8b5 !important;
}
form {
  // direction: rtl;
  // text-align: right;
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 40px #00000008;
  border-radius: 17px;
  input {
    height: 50px;
    background: #efefef 0% 0% no-repeat padding-box;
    border-radius: 11px;
    font-size: 14px;

    opacity: 0.49;
  }
  input:focus {
    border: none;
    box-shadow: none;
    outline: none;
    background: #efefef 0% 0% no-repeat padding-box;
  }
  .input-group {
    padding: 0 0 15px 0;
  }
  ::v-deep label {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #212121;
    line-height: 50px;
  }
  .file-name {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #cbcbcb;
    line-height: 47px;
    padding: 10px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-family: "Poppins";
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #cbcbcb;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "Poppins";
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #cbcbcb;
  }

  ::placeholder {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #cbcbcb;
    letter-spacing: 0px;
    color: #cbcbcb;
  }

  .fileUploadDiv {
    height: 50px;
    background: #efefef 0% 0% no-repeat padding-box;
    border-radius: 11px;
  }
  .plus-icon {
    font-size: 20px;
    padding: 10px;
    line-height: 30px;
    color: #5dbe9b;
    float: right;
  }

  .fileUploadDiv input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50px;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
}
::v-deep .modal-dialog {
  height: 100%;
  .modal-content {
    height: 100%;
    max-height: 98%;
    overflow: auto;
    .insight-image {
      height: 100%;
      width: 100%;
    }
  }
}
.mobile-heading {
  // text-align: right;
  font-size: 27px;
  line-height: 27px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
}

.active-social {
  background: #5ebe9b 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  i {
    color: #fff !important;
  }
}
</style>
